<template>
  <scroll-layout class="feedback-list">
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @clean="handleSearchClean"
        @click="handleSearchConfirm"></ykc-search>
    </template>
    <ykc-table
      ref="YkcTable"
      operateFixedType="right"
      :data="tableData"
      :title="tableTitle"
      :columns="tableColumns"
      :operateButtons="tableOperateButtons.filter(item => item.enabled())">
      <template #headerButtons>
        <div class="table-header-button">
          <ykc-button v-rbac="'maintain:user:feedback:export'" type="plain" @click="doExportFile">
            导出
          </ykc-button>
        </div>
      </template>
      <ykc-pagination
        align="right"
        slot="pagination"
        ref="YkcPagination"
        :total="pageInfo.total"
        :page-size.sync="pageInfo.size"
        :current-page.sync="pageInfo.current"
        @size-change="handlePaginationSizeChange"
        @current-change="handlePaginationCurrentChange"></ykc-pagination>
    </ykc-table>
    <Reply v-if="showDrawer" :id="id" @confirm="requestList" @close="showDrawer = false"></Reply>
  </scroll-layout>
</template>

<script>
  import { code, getLastTime, offlineExport } from '@/utils';
  import Reply from './Reply.vue';
  import { omManagementApi } from '@/service/apis';

  export default {
    name: 'feedbackList',
    components: {
      Reply,
    },
    data() {
      return {
        evaluateId: '',
        showDrawer: false,
        searchParams: {
          date: [getLastTime('yyyy-MM-dd', 30), getLastTime('yyyy-MM-dd', 0)],
          createStartTime: getLastTime('yyyy-MM-dd', 30),
          createEndTime: getLastTime('yyyy-MM-dd', 0),
          stationName: '',
          userAccountNo: '',
          feedbackType: '',
        },
        tableTitle: '反馈列表',
        tableData: [],
        tableOperateButtons: [], // 列表操作
        tableColumns: [
          { label: '反馈单号', prop: 'id', minWidth: '200px' },
          { label: '反馈时间', prop: 'createdTime', minWidth: '200px' },
          { label: '电站名称', prop: 'stationName', minWidth: '150px' },
          { label: '用户账户', prop: 'userAccountNo', minWidth: '200px' },
          { label: '反馈类型', prop: 'feedbackTypeName', minWidth: '100px' },
          {
            label: '处理状态',
            prop: 'feedbackStatus',
            scopedSlots: {
              default: ({ row }) => {
                const feedbackStatus = row.feedbackStatus === 1 ? 'status-green' : 'status-blue';
                return (
                  <span class={['feedbackStatus', feedbackStatus]}>
                    {+row.feedbackStatus === 1 ? '已处理' : '待处理'}
                  </span>
                );
              },
            },
            minWidth: '100px',
          },
          { label: '处理人', prop: 'dealerName', minWidth: '100px' },
          { label: '处理时间', prop: 'dealTime', minWidth: '180px' },
        ],
        pageInfo: {
          current: 1,
          size: 10,
        },
      };
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcDatePicker',
            key: 'date',
            label: '时间周期',
            placeholder: '请选择时间周期',
          },
          {
            comName: 'YkcInput',
            key: 'stationName',
            label: '电站名称',
            placeholder: '请输入电站名称',
          },
          {
            comName: 'YkcInput',
            key: 'userAccountNo',
            label: '用户账号',
            placeholder: '请输入用户账号',
          },
          {
            comName: 'YkcDropdown',
            key: 'feedbackType',
            label: '反馈类型',
            placeholder: '请选择反馈类型',
            data: [
              { id: '', name: '全部' },
              { id: '0', name: '日常反馈' },
              { id: '1', name: '设备故障' },
            ],
          },
        ];
      },
    },
    created() {
      this.requestList();
      this.initData();
    },
    methods: {
      /**
       * 初始数据
       */
      initData() {
        this.tableOperateButtons = [
          {
            enabled: () => code('maintain:user:feedback:reply'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.id = row.id;
                  this.showDrawer = true;
                }}>
                处理
              </ykc-button>
            ),
          },
          {
            text: '详情',
            enabled: () => code('maintain:user:feedback:detail'),
            handleClick: (btn, { row }) => {
              this.$router.push({
                path: '/operationManagement/feedback/feedbackDetails',
                query: { id: row.id },
              });
            },
          },
        ];
      },
      /**
       * 点击筛选查询按钮
       * */
      handleSearchConfirm(searchData) {
        this.pageInfo.current = 1;
        Object.assign(this.searchParams, searchData);

        if (searchData.date && searchData.date.length > 0) {
          [this.searchParams.createStartTime, this.searchParams.createEndTime] = searchData.date;
        }
        this.requestList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);

        // 近30天初始化时间
        const startDate = getLastTime('yyyy-MM-dd', 30);
        const endDate = getLastTime('yyyy-MM-dd', 0);

        this.searchParams.date = [startDate, endDate];
        this.searchParams.createStartTime = startDate;
        this.searchParams.createEndTime = endDate;
      },
      /**
       * 分页器页数
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.requestList();
      },
      /**
       * 分页器条数
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.requestList();
      },
      /**
       * 请求列表数据
       */
      requestList() {
        const reqParams = {
          ...this.searchParams,
          ...this.pageInfo,
        };
        omManagementApi
          .feedbackList(reqParams)
          .then(res => {
            this.tableData = res.records || [];
            this.pageInfo.total = res.total || 0;
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 导出
       */
      doExportFile() {
        offlineExport({
          downloadType: 'station_feedback_export',
          jsonNode: {
            ...this.searchParams,
          },
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .feedback-list {
    .ykc-table {
      .el-table .el-table__row {
        .cell .feedbackStatus {
          padding-left: 8px;
          position: relative;
        }

        .cell .feedbackStatus::after {
          display: block;
          content: '';
          width: 5px;
          height: 5px;
          border-radius: 100%;
          position: absolute;
          top: 5.5px;
          left: 0;
        }

        .cell .status-blue::after {
          background: #4d8cfd;
        }

        .cell .status-green::after {
          background: #02c498;
        }
      }
    }
  }
</style>
