<template>
  <ykc-drawer
    :title="'反馈处理'"
    :show="true"
    :on-close="onAddDrawerClose"
    :before-cancel="onAddDrawerCancel"
    :before-ensure="submitForm">
    <div class="drawer-main-body">
      <ykc-detail-plus :labelWidth="80">
        <ykc-detail-item-plus label="反馈单号">
          {{ detailInfo.id || '——' }}
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="反馈时间">
          {{ detailInfo.createdTime || '——' }}
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="电站名称">
          {{ detailInfo.stationName || '——' }}
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="用户账号">
          {{ detailInfo.userAccountNo || '——' }}
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="反馈类型">
          {{ detailInfo.feedbackTypeName }}
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="反馈内容">
          {{ detailInfo.feedbackDesc || '——' }}
        </ykc-detail-item-plus>
        <div class="clearfix"></div>
        <el-divider></el-divider>
        <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" class="el-form-wrap">
          <ykc-form-item label="处理意见" prop="dealDesc">
            <ykc-input
              maxlength="200"
              type="textarea"
              placeholder="请输入处理意见"
              v-model="ruleForm.dealDesc"></ykc-input>
          </ykc-form-item>
        </ykc-form>
      </ykc-detail-plus>
    </div>
  </ykc-drawer>
</template>

<script>
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { omManagementApi } from '@/service/apis';

  export default {
    data() {
      return {
        detailInfo: {},
        ruleForm: {
          dealDesc: '',
        },
        rules: {
          dealDesc: [{ required: true, message: '请输入回复内容', trigger: 'blur' }],
        },
      };
    },
    props: {
      id: {
        required: true,
      },
    },
    mounted() {
      this.getOrderDetail();
    },
    methods: {
      getOrderDetail() {
        omManagementApi
          .feedbackDetail({
            id: this.id,
          })
          .then(res => {
            this.detailInfo = res;
            this.ruleForm.dealDesc = res.dealDesc;
          })
          .catch(err => {
            console.log(err);
          });
      },
      submitForm() {
        return new Promise(resolve => {
          this.$refs.ruleForm.validate(valid => {
            if (valid) {
              omManagementApi
                .replyFeedback({
                  id: this.id,
                  dealDesc: this.ruleForm.dealDesc,
                })
                .then(() => {
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  this.$emit('close');
                  this.$emit('confirm');
                  resolve();
                });
            }
          });
        });
      },
      onAddDrawerClose(done) {
        done();
        this.$emit('close');
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: dialogDone => {
            dialogDone();
            this.$emit('close');
          },
          onConfirm: dialogDone => {
            dialogDone();
            drawerDone();
            this.$emit('close');
          },
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .clearfix {
    clear: both;
  }
</style>
